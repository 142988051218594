$(document).ready(function () {

  initHandleAnchor();
  initScrollableLinks();
  initTrigger();
  initStickyHeader();

});

function initScrollableLinks() {

	$('.scroll-init').on('click',function(e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: $($(this).attr('href')).offset().top,
		}, 800);
	});

}

function initTrigger() {

  $('.sidebar__trigger').on('click',function() {
		$(this).toggleClass('visible');
    $('.sidebar__inner').toggleClass('visible');
	});

}

function initStickyHeader() {

  if($(window).width() <= 1024) {
    var menu = document.querySelector('.sidebar');
  	var origOffsetY = menu.offsetTop;
  	function scroll () {
  		if ($(window).scrollTop() > origOffsetY) {
  			$('.sidebar').addClass('scrolling');
  		} else {
  			$('.sidebar').removeClass('scrolling');
  		}
  	}
  	scroll();
  	document.onscroll = scroll;
  }

}

function initHandleAnchor() {

	var anchor = getUrlParameter('anchor');
	if(!anchor) return false;
	$('html, body').animate({
		scrollTop: $("#" + anchor).offset().top
	}, 600);

}

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;
  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
};
